/* eslint-disable jsx-a11y/accessible-emoji */
import { graphql, useStaticQuery, withPrefix } from "gatsby"
import Img from "gatsby-image"
import scrollTo from "gatsby-plugin-smoothscroll"
import React from "react"
import Obfuscate from "react-obfuscate"
import styled from "styled-components"
import { H2 } from "../MDXElements/H2"
import { Container } from "./Container"
import Link from "./Link"
import { LogoGrayscaleLight } from "./LogoGrayscaleLight"
import { ResetButton } from "./ResetButton"

const query = graphql`
  {
    file(relativePath: { eq: "pp.jpg" }) {
      childImageSharp {
        fixed(quality: 100, width: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const DaArrow = styled.span`
  display: inline-block;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    transform: rotate(90deg) translateX(-1px);
  }
`

const DaFinga = styled.span`
  display: inline-block;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    transform: rotate(90deg);
  }
`

const StyledAndreLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.colors.blue};
  }
`

const StyledFooter = styled.footer`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.whtie};
  padding: 3rem 0;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    flex-direction: column;
  }
`

const Bio = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    flex-direction: column;
  }
`

const Headshot = styled.div`
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  left: -5px;
  margin-right: 2rem;
  flex-shrink: 0;

  > div {
    left: -25%;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    left: 0;
    margin-right: 0;
    margin-bottom: 2rem;
  }
`

const MeMeMe = styled.div`
  max-width: 40ch;
  color: ${props => props.theme.colors.white};
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    margin-bottom: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column-reverse;
    > p {
      margin-top: 0;
    }
    > p:first-child {
      margin-top: 1rem;
    }
  }
`

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
  margin-left: 2rem;

  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    margin-left: 0;
    align-items: center;
  }
`

const ImportantLink = styled.span`
  font-size: 1.5rem;
  a {
    color: ${props => props.theme.colors.white};
  }
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    font-size: 1.25rem;
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`

const FooterFooter = styled(Flex)`
  border-top: 1px solid ${props => props.theme.colors.black75};
  margin-top: 2rem;
  padding-top: 2rem;
`

const LogoWrap = styled.div`
  width: 125px;
  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    order: 2;
    margin-top: 2rem;
  }
`

const Social = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;

  > li {
    flex: 0 0 auto;
    margin-left: 1rem;
  }

  a:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.white};
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.md}px) {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;

    > li:first-child {
      margin-left: 0;
    }
  }
`

export const Footer = props => {
  const data = useStaticQuery(query)
  return (
    <StyledFooter {...props} id="contact">
      <Container>
        <Flex {...props}>
          <Bio>
            <Headshot>
              <Img
                fixed={data.file.childImageSharp.fixed}
                alt="Martin Lindberg's face."
              />
            </Headshot>
            <MeMeMe>
              <p>
                I'm Martin Lindberg, a curious digital designer with a technical
                skill set. Hit me up!{" "}
                <DaFinga role="img" aria-label="Finger pointing to the right.">
                  👉
                </DaFinga>
              </p>
              <p style={{ fontSize: ".875rem", fontStyle: "italic" }}>
                <DaArrow>←</DaArrow> My essence as captured by{" "}
                <StyledAndreLink to="https://andrereminisce.com/" {...props}>
                  Andre Ruth
                </StyledAndreLink>
                !
              </p>
            </MeMeMe>
          </Bio>
          <Contact>
            <ImportantLink>
              <Obfuscate email="martin@martinlindberg.me" />
            </ImportantLink>
            <ImportantLink>
              <Link
                to={withPrefix("/pdfs/MartinLindberg_Resume.pdf")}
                id="resume-pdf"
              >
                résumé.pdf
              </Link>
            </ImportantLink>
          </Contact>
        </Flex>
        <FooterFooter>
          <ResetButton onClick={() => scrollTo("#___gatsby")}>
            <LogoWrap>
              <LogoGrayscaleLight />
            </LogoWrap>
          </ResetButton>
          <Social>
            <li>
              <Link to="https://www.vimeo.com/martinlindberg">
                <H2>Vimeo</H2>
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/fartin______martin">
                <H2>Instagram</H2>
              </Link>
            </li>
            <li>
              <Link to="https://www.github.com/fartinmartin">
                <H2>Github</H2>
              </Link>
            </li>
            <li>
              <Link to="https://www.linkedin.com/in/-martinlindberg">
                <H2>LinkedIn</H2>
              </Link>
            </li>
          </Social>
        </FooterFooter>
      </Container>
    </StyledFooter>
  )
}
