import { useWindowHeight } from "@react-hook/window-size"
import scrollTo from "gatsby-plugin-smoothscroll"
import React, { useContext } from "react"
import { animated, useSpring } from "react-spring"
import styled from "styled-components"
import { H2 } from "../MDXElements/H2"
import { ScrollContext } from "./../Global/ScrollContext"
import { Container } from "./Container"
import { Logo } from "./Logo"
import { ReelLightbox } from "./ReelLightbox"
import { ResetButton } from "./ResetButton"

const ContactButton = styled(ResetButton)`
  pointer-events: auto;
  color: ${props => props.theme.colors.blue};
`

const LogoWrap = styled.div`
  width: 125px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  height: 7.5rem;

  a {
    display: inline-block;
  }

  > * {
    flex: 1;
  }

  > *:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > *:nth-child(3) {
    text-align: right;
  }
`

const StyledHeaderIndex = styled.header`
  position: fixed;
  width: 100%;
  z-index: 1000;
  pointer-events: none;
`

export const HeaderIndex = props => {
  const { scrollY } = useContext(ScrollContext)
  const windowHeight = useWindowHeight()

  const [logoAnim, setLogoAnim] = useSpring(() => ({
    opacity: 0,
    transform: "translateY(-100%)",
  }))

  setLogoAnim(
    scrollY > windowHeight / 2
      ? { opacity: 1, transform: "translateY(0%)" }
      : { opacity: 0, transform: "translateY(-100%)" }
  )

  return (
    <StyledHeaderIndex {...props}>
      <Container>
        <Flex>
          <div>
            <ReelLightbox />
          </div>
          <div>
            <ResetButton
              style={{ pointerEvents: "auto" }}
              onClick={() => scrollTo("#___gatsby")}
            >
              <animated.div style={logoAnim}>
                <LogoWrap>
                  <Logo />
                </LogoWrap>
              </animated.div>
            </ResetButton>
          </div>
          <div>
            <ContactButton onClick={() => scrollTo("#contact")}>
              <H2>Contact</H2>
            </ContactButton>
          </div>
        </Flex>
      </Container>
    </StyledHeaderIndex>
  )
}
