import React, { useEffect } from "react"
import styled from "styled-components"
import textBalancer from "text-balancer"
import { Footer } from "./../components/Global/Footer"
import { Header } from "./../components/Global/Header"
import { MySEO } from "./../components/Global/MySEO"
import { useSiteMetadata } from "./../hooks/useSiteMetadata"
import "./../styles/styles.css"

const Main = styled.main`
  margin-top: ${props => (!props.pageContext.title ? 0 : `7.5rem`)};
  z-index: 10;
`

export const Layout = props => {
  const { pageContext, children } = props
  const siteMetadata = useSiteMetadata()
  useEffect(() => textBalancer.balanceText(), [])

  return (
    <>
      <MySEO {...props} siteMetadata={siteMetadata} />
      <Header pageContext={pageContext} siteMetadata={siteMetadata} />
      <Main pageContext={pageContext}>{children}</Main>
      <Footer pageContext={pageContext} siteMetadata={siteMetadata} />
    </>
  )
}

Layout.defaultProps = {
  frontmatter: "page",
  fields: "page",
}
