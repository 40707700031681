import React from "react"
import SEO from "./SEO"

export const MySEO = props => {
  const {
    pageContext: { slug },
    frontmatter,
    siteMetadata,
  } = props
  const {
    description,
    title,
    image,
    siteUrl,
    siteLanguage,
    siteLocale,
    authorName,
  } = siteMetadata
  const { title: projectTitle, kicker, featuredMedia, date } = frontmatter

  return (
    <SEO
      title={title}
      titleTemplate={projectTitle ? `/ ${projectTitle}` : ""}
      description={kicker ? kicker : description}
      image={
        featuredMedia
          ? `${siteUrl}${featuredMedia[0].image.publicURL}`
          : `${siteUrl}${image}`
      }
      pathname={slug ? `${siteUrl}${slug}` : siteUrl}
      siteLanguage={siteLanguage}
      siteLocale={siteLocale}
      author={authorName}
      article={projectTitle ? true : null}
      publishedDate={date ? date : null}
      modifiedDate={date ? new Date(Date.now()).toISOString() : null}
    >
      <link rel="stylesheet" href="https://use.typekit.net/mlu6lcm.css" />
    </SEO>
  )
}
