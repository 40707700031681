import React from "react"
import { HeaderIndex } from "./HeaderIndex"
import { HeaderProject } from "./HeaderProject"

export const Header = props => {
  return props.pageContext.title ? (
    <HeaderProject {...props} />
  ) : (
    <HeaderIndex {...props} />
  )
}
