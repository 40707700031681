import scrollTo from "gatsby-plugin-smoothscroll"
import React from "react"
import styled from "styled-components"
import { H2 } from "../MDXElements/H2"
import { Container } from "./Container"
import Link from "./Link"
import { Logo } from "./Logo"
// import { LogoGrayscale } from "./LogoGrayscale"
import { ReelLightbox } from "./ReelLightbox"
import { ResetButton } from "./ResetButton"

const ContactButton = styled(ResetButton)`
  pointer-events: auto;
  color: ${props => props.theme.colors.blue};
`

const LogoWrap = styled.div`
  width: 125px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  height: 7.5rem;

  a {
    display: inline-block;
  }

  > * {
    flex: 1;
  }

  > *:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > *:nth-child(3) {
    text-align: right;
  }
`

const StyledHeaderProject = styled.header`
  position: fixed;
  width: 100%;
  z-index: 1000;
  pointer-events: none;
`

export const HeaderProject = props => (
  <StyledHeaderProject {...props}>
    <Container>
      <Flex>
        <div>
          <ReelLightbox />
        </div>
        <div>
          <Link to="/" style={{ pointerEvents: "auto" }}>
            <LogoWrap>
              <Logo />
              {/* <LogoGrayscale /> */}
            </LogoWrap>
          </Link>
        </div>
        <div>
          <ContactButton onClick={() => scrollTo("#contact")}>
            <H2>Contact</H2>
          </ContactButton>
        </div>
      </Flex>
    </Container>
  </StyledHeaderProject>
)
