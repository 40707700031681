import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  @media screen and (min-width: ${props =>
      props.theme.breakpoints.sm}px) and (max-width: ${props =>
      props.theme.breakpoints.md}px) {
    padding: 0 2rem;
  }
`

export const Container = ({ children, props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
)
