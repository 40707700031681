import FsLightbox from "fslightbox-react"
import React, { useState } from "react"
import styled from "styled-components"
import { H2 } from "./../MDXElements/H2"

const Motion = styled.span`
  display: inline;
  @media screen and (max-width: ${props => props.theme.breakpoints.sm}px) {
    display: none;
  }
`

const StyledH2 = styled.div`
  color: #5b73b0;
  cursor: pointer;
  display: inline-block;
  pointer-events: auto;
`

const VideoEmbed = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  background: url("https://i.vimeocdn.com/video/906331666.jpg?mw=2560&mh=1440&q=70");
  background-size: cover;
  pointer-events: auto;
`

const VideoWrapper = styled.div`
  // position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100vw;
  pointer-events: auto;
`

export const ReelLightbox = () => {
  const [toggler, setToggler] = useState(false)
  return (
    <>
      <StyledH2 onClick={() => setToggler(!toggler)} id="motion-reel-btn">
        <H2>
          <Motion>Motion</Motion> Reel
        </H2>
      </StyledH2>
      {/* TODO: replace with SRL (if possible 🤔) */}
      <FsLightbox
        toggler={toggler}
        customSources={[
          <div>
            <VideoWrapper>
              <VideoEmbed
                src="https://player.vimeo.com/video/427415105?autoplay=1"
                title="Martin Lindberg's 2020 Motion Reel"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              />
            </VideoWrapper>
          </div>,
        ]}
      />
    </>
  )
}
